
@font-face {
    font-family: 'Edmondsans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Edmondsans Regular'), url('../assets/fonts/EdmondsansRegular_web.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Edmondsans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Edmondsans Medium'), url('../assets/fonts/EdmondsansMedium_web.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Edmondsans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Edmondsans Bold'), url('../assets/fonts/EdmondsansBold_web.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'RobotoSlab Regular';
    font-style: normal;
    font-weight: normal;
    src: local('RobotoSlab Regular'), url('../assets/fonts/RobotoSlab-Regular.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'RobotoSlab Black';
    font-style: normal;
    font-weight: normal;
    src: local('RobotoSlab Black'), url('../assets/fonts/RobotoSlab-Black.woff') format('woff');
    font-display: swap;
}