@import '../../theme/constants';
@import "react-datepicker/dist/react-datepicker.css";

.ContactForm{
    background: $accent-blue;
    position: relative;
    .title--1{
        color: #fff;
    }
    button.white{
        color: $accent-blue;
        border-color: #fff;
        background-color: #fff;
        padding: 20px 30px;
        &:hover{
            background-color: $accent-blue;
            border-color:  #fff;
            color: #fff;
        }
    }
    
    &__text{
        margin-bottom: 3.2rem;
        p{
            color: #99C9FF;
        }
    }

    &__form{
        .title--2{
            color: #fff;
        }

        input{
            border: 3px solid #fff;
            padding: 12px 18px;
            font-family: 'Edmondsans Medium', sans-serif;
            font-size: 18px;
            color: #fff;
            background: transparent;
            margin-bottom: 24px;
            width: 100%;
            box-sizing: border-box;
            &::placeholder{
                color: #fff!important;
                opacity: 1;
            }
            border-radius: 10px;
        }
        input:-ms-input-placeholder{
            color: #fff!important;
        }

        
        &__inputcontainer{
            position: relative;
            width: 100%;
        }
        &__inputerror{
            position: absolute;
            bottom: 0;
            color: #fff;
            font-size: 1.5rem;
            font-family: 'Edmondsans Medium', sans-serif;
            left: 0;
        }

        .react-datepicker__input-container {
            width: inherit;
        }
        

        .react-datepicker-wrapper{
            width: 100%;
        }

        

        .react-datepicker{
            font-size: 2rem;
            font-family: 'Edmondsans Medium', sans-serif;
            width: 100%;
        }

        .react-datepicker-popper{
            width: 100%;
        }

        .react-datepicker__month-container{
            width: 69%;
        }

        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
            font-size: 2rem;
            font-family: 'Edmondsans Medium', sans-serif;
            font-weight: normal;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
            right: 32%;
        }

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            width: 13%;
            line-height: 3rem;
        }

        .react-datepicker__time-container{
            width: 30.5%;
            float: left;
        }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
            width: 100%;
        }
        

        &.black{
            .title--2{
                color: #000;
            }
            input{
                border: 3px solid #000;
                color: #000;
                &::placeholder{
                    color: #000!important;
                }
            }
            .ContactForm__form__inputerror{
                color: #000;
            }
            input:-ms-input-placeholder{
                color: #000!important;
            }
            &__text{
                p{
                    color: #000;
                }
            }
        }
    }    

    &__ilustration{
        &--desktop{
            display: none;
        }
    }
    @media screen and (min-width: $min-desktop){
        &:before{
            background-color: $accent-blue;
            content: " ";
            position: absolute;
            left: -50vw;
            width: 150vw;
            display: block;
            top: 0;
            z-index: -1;
            height: 100%;
        }

        &__ilustration{
            &--desktop{
                display: block;
            }
            &--mobile{
                display: none;
            }
        }
    }


}