$min-desktop: 992px;
$min-big: 1200px;
$accent-blue: rgb(0,122,255);
$light-background: #fff;
$light-secondary-background: $accent-blue;
$dark-background: rgb(4,9,12);
$dark-secondary-background: rgb(29,32,34);
$light-color: #686B6D; 
$light-title: rgb(4,9,12);
$dark-color: #fff;
$dark-title: #fff;

@mixin theme_transition {
    transition: 0.5s background-color ease-out, 0.5s color ease-out, 0.5s border-color ease-out;
}