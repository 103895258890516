@import './../../theme/constants';
.PastClients{
    background-color: $accent-blue;
    text-align: center;
    position: relative;
    @include theme_transition;
    .dark &{
        background-color: $dark-secondary-background; 
    }
    h2 {
        color: #fff !important;
        margin-bottom: 2.4rem;
    }
    .brands{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        &__item{
            width: auto;
            height: auto;
            padding: 4%;
            max-width: 40%;
            max-height: 30px;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    @media screen and (min-width: $min-desktop){
        padding-top: 64px;
        padding-bottom: 41px;

        &:before{
            @include theme_transition;
            background-color: $accent-blue;
            content: " ";
            position: absolute;
            left: -50vw;
            width: 150vw;
            display: block;
            top: 0;
            z-index: -1;
            height: 100%;

            .dark &{
                background-color: $dark-secondary-background; 
            }
        }

        

        .brands{
            justify-content: center;
            &__item{
                width: auto;
                padding: 0 20px;
                max-height: 35px;
                margin-bottom: 20px;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
                &.invex{
                    max-width: 155px;
                }
                &.azteca{
                    max-width: 218px;
                }
                &.scotia{
                    max-width: 174px;
                }
                &.italika{
                    max-width: 155px;
                }
                &.elektra{
                    max-width: 146px;
                }
                &.sura{
                    padding-left: 31px;
                    max-height: 39px;
                }
            }
        }
    }
}