
@import '../../theme/constants';

.FixedTools{
    position: fixed;
    bottom: 0;
    right: 0;position: fixed;
    bottom: 40px;
    right: 10px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.16);
    box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.16);
    &__upbutton{
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        svg{
            fill: $accent-blue;
        }
        &.hide svg{
            display: none;
        }
    }
    &__whatsapp{
        display: block;
        width: 40px;
        position: relative;
        height: 40px;
        img{
            width: 52px;
            height: auto;
            position: absolute;
            left: -6px;
            top: -6px;
        }
        
    }
}