@import '../../theme/constants';
.Header{
    position: fixed;
    width: 100%;
    z-index: 11;
    top: 0;
    color: rgb(4,9,12);
    @include theme_transition;
    .container{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }  

    &__logo{
        height: 40px;
        width: auto;
    }

    &__controls{
        display: flex;
        align-items: center;
        &__language{
            &__option{
                cursor: pointer;
                &.active{
                    color: $accent-blue;
                    pointer-events: none;
                    cursor: default;
                }
            }
            &__icon{
                height: 32px;
                width: 32px;
                display: none;
            }
        }
        &__theme{
            &__click{
                cursor: pointer;
                height: 32px;
                width: 32px;;
                margin-left: 16px;
            }
        }
    }

    @media screen and (min-width: $min-desktop){
        .container{
            padding-top: 36px !important;
            padding-bottom: 36px !important;
        }
        
        &__logo{
            height: 60px;
            width: auto;
        }

        &__controls{
            &__language{
                &__icon{
                    display: inline;
                    margin-left: 1.2rem;
                }
            }
            &__theme{
                &__click{
                    margin-left: 28px;
                }
            }
        }
    }

    .dark &{
        background-color: $dark-secondary-background; 
    }
    .light &{
        background-color: #fff; 
    }
}