@import '../../theme/constants';

.Allies{
    p{
        span:first-child{
            margin-bottom: 1.8rem;
            display: block;
        }
        
    }


    @media screen and (min-width: $min-desktop) {
        p{
            span:first-child{
                margin-bottom: 0;
                display: inline;
            }
        }    
        &__illustration{
            position: relative;
            align-self: flex-end;
            img{
                width: 100%;
                position: absolute;
                bottom: -53px;
            }
        }
    }
    @media screen and (min-width: $min-big) {
        &__illustration{
            img{
                bottom: -86px;
            }
        }
    }
}