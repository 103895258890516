@import '../../theme/constants';

.ContactModal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width : 976px;
    z-index: 1000;
    border-radius: 25px;
    padding: 24px;
    position: absolute;
    background-color: #fff;
    display: flex;
    box-sizing: border-box;
    &__close{
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }
    &__content{
        width: 100%;
    }
    &__illustration{
        display: none;    
    }
    @media screen and (min-width: $min-desktop){
        padding: 78px;
        &__close{
            position: absolute;
            top: 42px;
            right:  42px;
        }
        &__content{
            width: 55%;
        }
        &__illustration{
            display: block;
            width: 45%;
            position: relative;
            img{
                position: absolute;
                right: -78px;
                bottom: 0px;
            }
            
        }
    }
    
}