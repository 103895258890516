@import '../../theme/constants';

.ParallaxAgile{
    // background-image: url('../../assets/img/videoMask.png');
    background-position: center;
    background-size: cover;
    text-align: center;
    position: relative;
    overflow: hidden;
    background: black;
    video{
        position: absolute;
        z-index: 1;
        opacity: 0.5;
        max-width: initial;
        left: 0;
        height: 100%;
        max-height: initial;
        top: 0;
    }
    &__content{
        position: relative;
        z-index: 2;
    }
    .title--1{
        color: #fff;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
    &__text{
        max-width: 970px;
        margin-left: auto;
        margin-right: auto;
        color: #B3ACA8;
    }
    &__tenets{
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        &__item{
            color: #fff;
            img{
                width: 48px;
                height: auto;
                margin-bottom: 12px;
            }
            margin-top: 48px;
        }
    }

    @media screen and (min-width: $min-desktop){

        .container{
           padding-top: 85px;
           padding-bottom: 70px;
        }
        video{
            width: 160%;
            height: auto;
            top: -30%;
            left: -30%;
        }

        .title--1{
            max-width: 50%;
        }
        &__tenets{
            max-width: 1020px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            &__item{
                &.loop-item{
                    margin-right: 18px;
                }
                img{
                    width: 72px;
                    height: auto;
                    margin-bottom: 24px;
                    &.loop{
                        padding: 21px;
                        padding-bottom: 18px;
                        width: 86px;
                    }
                }
                margin-right: 12px;
                &:first-child{
                    margin-left: 0;
                }
                p{
                    line-height: 3.6rem;
                }
                margin-top: 64px;
            }
        }
    }
}