@import '../../theme/constants';

.HeroSlider{
    height: calc(100vh - 80px);
    padding: 0;
    position: relative;
    &__slide{
        opacity: 0;
        transition: all 1s ease-in;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 85% center;
        background-size: cover;
        &.active{
            opacity: 1;
        }
        &__text{
            margin-left: 0;
            margin-right: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            
            .title--1{
                font-size: 3.6rem !important;
                line-height: 3.8rem;
                color: #fff;
                width: 90%;
                span{
                    color: rgb(98,99,105);
                }
            }
            .blue & {
                span{
                    color: #fff;
                    opacity: 0.5;
                }
            }
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            button {
                align-self: center;
                margin-bottom: 50px;
            }
        }
    }  

    @media screen and (min-width: $min-desktop){
        height: calc(100vh - 132px);
        min-height: 650px;
        max-height: 750px;
        &__slide{
            height: 100%;
            background-position: bottom right;
            background-size: cover;
            &__text{
                max-width: 39%;
                padding-right: 0;
                .title--1{
                    font-size: 7.2rem !important;
                    line-height: 7.2rem;
                    width: 100%;
                    span{
                        display: block;
                    }
                }
                position: absolute;
                display: block;

                button {
                    margin-bottom: 0;
                }
            }
        }

        
        &:before{
            background-color: #04090D;
            content: " ";
            position: absolute;
            left: -50vw;
            width: 150vw;
            display: block;
            top: 0;
            z-index: -1;
            height: 100%;
            transition: all 1s ease-in;
        }

        &.blue{
            &:before{
                background-color: #0048ff;
            }
        }

    }

    &__indicator{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 36px;
        width: 100%;
        cursor: pointer;
        &__item{
            width: 64px;
            height: 4px;
            background: rgb(216,216,216);
            opacity: 0.5;
            &.active{
                opacity: 1;
            }
            margin: 8px;
        }
    }

    .light &{
        button {
            background-color: #fff;
            border-color:  #fff;
            color:  $accent-blue;
            &:hover{
                background-color: $accent-blue;
                border-color:  $accent-blue;
                color: #fff;
            }
        }
        .blue {
            button {
                background-color: transparent;
                border-color:  #fff;
                color: #fff;
                padding: 20px 30px;
                &:hover{
                    color: $accent-blue;
                    border-color: #fff;
                    background-color: #fff;
                }
            }
           
        }
    }
    .dark &{
        button {
            background-color: #fff;
            border-color:  #fff;
            color:  $accent-blue;
            &:hover{
                background-color: $accent-blue;
                border-color:  $accent-blue;
                color: #fff;
            }
        }
        .blue  {
            button {
                background-color: transparent;
                border-color:  #fff;
                color: #fff;
                padding: 20px 30px;
                &:hover{
                    color: $accent-blue;
                    border-color: #fff;
                    background-color: #fff;
                }
            }
           
        }
    }

    @media screen and (min-width: $min-desktop){

        .light &{
            button {
                background-color: transparent;
                border-color:  $accent-blue;
                color:  $accent-blue;
                &:hover{
                    background-color: $accent-blue;
                    border-color:  $accent-blue;
                    color: #fff;
                }
            }
        }
        .dark &{
            button {
                background-color: #fff;
                border-color:  #fff;
                color:  $accent-blue;
                &:hover{
                    background-color: $accent-blue;
                    border-color:  $accent-blue;
                    color: #fff;
                }
            }
        }
        
    }
}