@import './../../theme/constants';
.Software{
    &__button{
        padding: 20px 20px;
        width: 300px;
        margin: 0 auto;
        display: block;
    }
    &__illustration{
        img{
            width: 100%;
            margin-bottom: 1px;
            height: auto;
        }
    }
    @media screen and (min-width: $min-desktop){
        &__illustration{
            max-width: 570px;
            max-height: 700px;
            padding: 36px;
        }
        &__button{
            padding: 20px 20px;
            width: 300px;
            margin: 0;
        }
    }
}