@import '../../theme/constants';
.Experiences{
    .title--1{
        margin-bottom: 2rem;
    }
    &__blurb{
        color: rgb(4,9,12);
        &__quote{
            margin-bottom: 4.8rem;
        }
        &__author{
            &__name{
                font-family: 'RobotoSlab Black', serif;
            }
            margin-bottom: 2rem;
        }
        &__visible{
            overflow: hidden;
            position: relative;
        }
        &__container{
            width: 300%;
            display: flex;
            position: relative;
            transition: 0.5s ease-in all;
        }
    }
    .dark &{
        &__blurb{
            color: #fff;
        }
    }
    &__controls{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;    
        button{
            width: 75px;
            padding: 0;
            border: none;
            cursor: auto;
            img{
                width: 100%;
                height: auto;
            }
            &.left,
            &.right {
                cursor: pointer;
            }
        }
    }

    &__video{
        position: relative;
        img{
            width: 100%;
            height: auto;
        }
        &__button{
            background: rgba($accent-blue, 0.7);
            color: white;
            position: absolute;
            width: 30%;
            height: 22%;
            border-radius: 10px;
            display: block;
            top: 37%;
            left: 35%;
            text-align: center;
            svg{
                fill: white;
                width: 100%;
                height: 100%;
                display: block;
            }


        }
    }

    @media screen and (min-width: $min-desktop) {
        .title--1{
            margin-bottom: 4.8rem;
            width: 90%
        }
        &__blurb{
            &__quote{
                margin-bottom: 4.8rem;
            }
            &__author{
                margin-bottom: 5.8rem;
            }
        }
    }
}