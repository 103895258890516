@import "constants.scss";
@import "reset.css";
@import "fonts.scss";

html {
    font-size: 10px;
}

body {
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-family: 'RobotoSlab Regular', sans-serif;
    margin: 0 auto;
    @include theme_transition;
}

a{
    color: $accent-blue;
    text-decoration: none;
}

#root{
    position: relative;
    overflow: hidden;
    padding-top: 80px;

}

.margin-text{
    margin-bottom: 1.8rem;
}

.container{
    width: 100%;
    padding: 48px 24px;
    box-sizing: border-box;
    &.cols {
        display: flex;
        flex-direction: column;
        .col{
            width: 100%;
            margin: 0 0 24px 0;
            &:last-child{
                margin: 0;
            }
        }
    }
}

.title--1{
    font-family: 'Edmondsans Bold', sans-serif;
    font-size: 3.2rem;
    line-height: 3.2rem;
    margin-bottom: 2.6rem;
}

.title--2{
    font-family: 'Edmondsans Medium', sans-serif;
    font-size: 2rem;
    line-height: 2.8rem;
}

.list{
    font-family: 'Edmondsans Medium', sans-serif;
    font-size: 1.6rem;
    line-height: 2.8rem;
}

button{
    background: transparent;
    border: 2px solid;
    font-family: 'Edmondsans Bold', sans-serif;
    font-size: 2rem;
    line-height: 2rem;
    padding: 20px 28px;
    border-radius: 32px;
    @include theme_transition;
}

@media screen and (min-width: $min-desktop){
    #root{
        padding-top: 132px;
        
    }
    body {
        font-size: 1.8rem;
        line-height: 3.2rem;
    }   
    .margin-text{
        margin-bottom: 2.4rem;
    }

    .container{
        padding: 48px 48px;
        max-width: 1440px;
        margin: 0 auto;
        &.cols {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .col{
                width: calc(50% - 15px);
                margin: 0;
            }
        }
    }

    .title--1{
        font-size: 4.8rem;
        line-height: 5.6rem;
        margin-bottom: 2.4rem;
    }
    
    .title--2{
        font-size: 2.6rem;
        line-height: 2.8rem;
    }
}
@media screen and (min-width: $min-big){
    .container{
        padding: 80px 135px;
    }
}

.ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.6) !important;
    z-index: 100;
}

/*themes*/

body.light{
    color: $light-color;
    background-color: $light-background;
    h1, h2, h3, h4, h5, h6, .list{
        color: $light-title
    }
    

    button{
        &.blue{
            background-color: transparent;
            border-color:  $accent-blue;;
            color:  $accent-blue;;
            @media screen and (min-width: $min-desktop){
                background-color: $accent-blue;
                border-color:  $accent-blue;
                color: #fff;
            }
            &:hover{
                background-color: transparent;
                border-color:  $accent-blue;;
                color:  $accent-blue;;
            }
        }
        &.black{
            background-color: transparent;
            border-color:  $accent-blue;;
            color:  $accent-blue;;
           
            &:hover{
                background-color: #fff;
                border-color:  #fff;
                color: $accent-blue;
            }
        }
    }
}

body.dark{
    color: $dark-color;
    background-color: $dark-background;
    h1, h2, h3, h4, h5, h6, .list{
        color: $dark-title;
    }

    button{
        &.blue{
           
            background-color: transparent;
            border-color:  $accent-blue;
            color:  $accent-blue;
            &:hover{
                background-color: $accent-blue;
                border-color:  $accent-blue;
                color: #fff;
            }
        }

        &.black{
            background-color: #fff;
            border-color:  #fff;
            color: $accent-blue;
            &:hover{
                background-color: transparent;
                border-color:  $accent-blue;
                color:  $accent-blue;
            }
        }
    }
} 