@import '../../theme/constants';

.Footer{
    padding-top: 48px;
    padding-bottom: 48px;

    &__title{
        font-size: 3.2rem;
        font-family: 'Edmondsans Medium', sans-serif;
        margin-bottom: 24px;
    }

    &__info{
        font-size: 1.4rem;
        line-height: 2.0rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (min-width: $min-desktop){
        padding-top: 72px;
        padding-bottom: 60px;

        text-align: center;

        &__title{
            font-size: 3.6rem;
            margin-bottom: 30px;
        }
    
        &__info{
            font-size: 1.6rem;
            line-height: 2.4rem;
            position: relative;
            display: block;
        }
    
    }
}